import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Button,
    Card,
    CardBody
} from "reactstrap";
import { motion } from "framer-motion";
import Lottie from 'react-lottie';
import ReactPlayer from 'react-player';
import SweetAlert from "react-bootstrap-sweetalert";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";

import instagramLogo from "assets/img/instagram-logo.png";
import * as appStatusDeployed from 'assets/animations/appStatus/appStatusNotStarted.json';

import { FiVideo, FiLink } from "react-icons/fi";

import Api from 'api/index';

class InstagramIntegration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            accountData: null,
            accountMedia: [],
            sweetAlert: null
        };
    }

    componentDidMount() {
        this.getUrlParams();
    }

    async getUrlParams() {
        try {
            const query = new URLSearchParams(window.location.search);
            // Error
            let error = query.get("error");
            console.log(error);
            if(error) {
                this.props.triggerNotification("An error occured and we weren't able to connect your Instagram account.", "danger", "bc", 8);
                return;
            }
            // Authorisation code
            let authCode = query.get("code");
            if(typeof authCode === 'string' || authCode instanceof String) {
                authCode = authCode.replace('#_', '');
                await Api.connectInstagram({ auth_code: authCode, use_basic_display: false });
                let businessObj = Object.assign({}, this.props.business);
                businessObj.business_instagram_access_token = true;
                this.props.actions.updateBusiness(businessObj);
                this.props.triggerNotification("Instagram account connected successfully.", "success", "bc", 4);
                this.loadInstagramData();
                return;
            }
            // No parameter defined try and get Instagram data
            this.loadInstagramData();
        } catch(err) {
            console.log(err);
            this.props.triggerNotification("An error occured and we weren't able to connect your Instagram account.", "danger", "bc", 8);
        }
    }

    async loadInstagramData() {
        try {
            this.props.actions.loadLoadingSpinner(true);
            let response = await Api.getInstagramData();
            let data = response.data;
            let accountMedia = data.accountMedia;
            this.setState({ accountData: data.accountData, accountMedia: accountMedia});
            this.props.actions.loadLoadingSpinner(false);
        } catch(e) {
            if(e.response && e.response.data && (e.response.data.message === "no_access_token" || e.response.data.message === "invalid_access_token")) {
                let businessObj = Object.assign({}, this.props.business);
                businessObj.business_instagram_access_token = false;
                this.props.actions.updateBusiness(businessObj);
                if(e.response.data.message === "invalid_access_token"){
                    this.props.triggerNotification("Connection expired. Please reconnect to Instagram.", "danger", "bc", 8);
                }
            } else {
                this.props.triggerNotification("Unable to establish connection to Instagram.", "danger", "bc", 8);
            }
            this.props.actions.loadLoadingSpinner(false);
        }
    }

    async handleDisconnect() {
        this.setState({
            sweetAlert: (
                <SweetAlert
                    warning
                    title="Are you sure?"
                    onConfirm={() => this.disconnect()}
                    onCancel={() => this.setState({ sweetAlert: null })}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="muted"
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                >
                    Are you sure that you want to disconnect your Instagram account?
                </SweetAlert>
            ),
        });
    }

    handleConnect() {
        window.location.href = 'https://api.instagram.com/oauth/authorize?client_id=555830644038245' +
            '&enable_fb_login=0' +
            '&force_authentication=1' +
            '&redirect_uri=https://hub.whatstyle.com/admin/instagram-integration' +
            '&response_type=code' +
            '&scope=instagram_business_basic';
    }

    async disconnect() {
        try {
            this.setState({ sweetAlert: null });
            this.props.actions.loadLoadingSpinner(true);
            await Api.disconnectInstagram();
            let businessObj = Object.assign({}, this.props.business);
            businessObj.business_instagram_access_token = false;
            this.props.actions.updateBusiness(businessObj);
            this.props.actions.loadLoadingSpinner(false);
        } catch(e) {
            this.props.triggerNotification("An error occured and we weren't able to disconnect your Instagram account.", "danger", "bc", 8);
            this.props.actions.loadLoadingSpinner(false);
        }
    }

    handleOpenInstagram() {
        if(this.state.accountData && this.state.accountData.username) {
            window.open(`https://www.instagram.com/${this.state.accountData.username}`, '_blank');
        }
    }

    renderConnect() {
        return (
            <Row>
                <Col xs={12} sm={8} md={6} lg={4} xl={4} className="ms-auto me-auto">
                    <div className="gallery-empty-container">
                        <div className="centered-container text-center">
                            <div style={{ marginBottom: 15, width: 50 }}>
                                <img src={instagramLogo} alt="instagram-logo" />
                            </div>
                            <h5>Connect Instagram</h5>
                            <p>Connect to Instagram to automatically show all of your Instagram posts on your app home screen.</p>
                            <Button color="primary" className="m-0 instagram-gradient" onClick={() => this.handleConnect()}>
                                Connect Instagram
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    renderInstagramImageCard(mediaObj, mediaIndex) {
        const imageCardAnim = {
            hidden: { y: 30, opacity: 0 },
            visible: {
                y: 0,
                opacity: 1,
                delay: mediaIndex
            },
        };
        if(mediaObj.media_type === "IMAGE" || mediaObj.media_type === "CAROUSEL_ALBUM") {
            return (
                <motion.div variants={imageCardAnim} initial="hidden" animate="visible" transition={{ delay: Number(mediaIndex / 20) }} onClick={() => window.open(mediaObj.permalink, '_blank')}>
                    <div
                        className="gallery-image-container"
                        style={{ backgroundImage: `url(${mediaObj.media_url})` }}
                    />
                </motion.div>
            );
        } else if(mediaObj.media_type === "VIDEO") {
            return (
                <motion.div variants={imageCardAnim} initial="hidden" animate="visible" transition={{ delay: Number(mediaIndex / 20) }} onClick={() => window.open(mediaObj.permalink, '_blank')}>
                    <FiVideo color={'#ffffff'} size={18} style={{ top: 10, right: 25, position: 'absolute' }}/>
                    <ReactPlayer
                        height={'unset'}
                        className="gallery-image-container"
                        style={{ maxWidth: '100%', marginBottom: 30, borderRadius: 6, paddingBottom: 0 }}
                        url={mediaObj.media_url}
                        playing={false}
                        volume={0}
                    />
                </motion.div>
            );
        }
    }

    renderStatusAnimation() {
        const defaultOptions = {
            loop: true,
            autoplay: true, 
            animationData: appStatusDeployed.default,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <div>
                <Lottie options={defaultOptions}/>
            </div>
        );
    }

    render() {
        return (
        <>
            <PanelHeader size="sm"/>
            {this.state.sweetAlert}
            <div className="content">
                {this.props.business.business_instagram_access_token ?
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <Card className="card-raised">
                                <CardBody>
                                    <div className="instagram-header-container">
                                        <div className="instagram-header-container">
                                            <div className="instagram-header-logo-container">
                                                <img src={instagramLogo} alt="instagram-logo" />
                                            </div>
                                            <div className="text-center instagram-header-handle-container">
                                                <div className="m-0" style={{ fontSize: '1.4em' }}>{this.state.accountData ? this.state.accountData.username : '----'}</div>
                                            </div>
                                        </div>
                                        <div className="d-none d-md-block">
                                            <div className="app-status-container" style={{ marginTop: -2 }}>
                                                <div style={{ height: 40, width: 40 }}>
                                                    {this.renderStatusAnimation()}
                                                </div>
                                                <div className="app-status-title">
                                                    Connected
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-none d-md-block" style={{ marginTop: 3 }}>
                                            <div className="mt-0" style={{ fontSize: '1.4em' }}>{this.state.accountData ? this.state.accountData.mediaCount : '----'} posts</div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Button
                                                color="primary"
                                                block
                                                className="m-0 mr-1 instagram-gradient"
                                                onClick={() => this.handleOpenInstagram()}
                                            >
                                                Open Instagram
                                            </Button>
                                            <Button
                                                color="danger"
                                                className="m-0 ml-1"
                                                onClick={() => this.handleDisconnect()}
                                            >
                                                <FiLink size={14}/>
                                            </Button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <Row>
                                {this.state.accountMedia.map((accountMediaObj, accountMediaIndex) => {
                                    return (
                                        <Col lg={3} md={3} sm={4} xs={12}>
                                            {this.renderInstagramImageCard(accountMediaObj, accountMediaIndex)}
                                        </Col>
                                    );
                                })}
                            </Row>
                            {this.state.accountMedia.length > 0 ?
                                <div>
                                    <hr/>
                                    <p className="text-center mb-0">
                                        Showing your most recent {this.state.accountMedia.length} posts.
                                    </p>
                                </div>
                            : null}
                        </Col>
                    </Row>
                : this.renderConnect()}
            </div>
        </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(InstagramIntegration);